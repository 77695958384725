import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as Parts from './components.jsx'

import './style.scss';

class Home extends Component{

  render(){

    return(
      <div className="main">

        <div className="home__banner">
          <div className="banner__panel">
            <h1 className="banner__title">Decentralised naming for wallets, websites, & more.</h1>
            <div className="banner__btns">
              <Link to="/search/trx" className="banner__btn">Explore</Link>
              <Link to="/search" className="banner__btn">Register</Link>
            </div>
          </div>
        </div>
        <Parts.SetUpDomain />
        <Parts.UserInfo />
        <Parts.AddressInfo/>
      </div>
    )
  }
}

export default Home;
