import React, { Component } from 'react'
import $ from 'jquery';
import { Link ,useLocation } from 'react-router-dom'
import styled from 'styled-components'

import './style.scss'
import{Profile} from './parts.jsx'


import {ReactComponent as HomeIcon} from '../../images/home.svg';
import {ReactComponent as ExploreIcon} from '../../images/explore.svg';
import {ReactComponent as Square4} from '../../images/square4.svg';
import {ReactComponent as TnsLogo} from '../../images/logo-sm.svg';

const MenuList = styled.ul.attrs({
  className: 'nav__menu',
})``
const MenuItem  = styled(Link).attrs(props=>({
  className : `nav__menu__item ${props.active === "true"?"active":""}`,

}))``;


const Menu = (props) =>{
  const { pathname } = useLocation();

  return(
    <MenuList>
      <MenuItem to="/home" active={`${pathname === "/home"}`}><HomeIcon/>Home</MenuItem>
      <MenuItem to="/search/trx" active={`${pathname === "/explore"}`}><ExploreIcon/>Explore</MenuItem>
      <MenuItem to="/search" active={`${pathname === "/search"}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>Register</MenuItem>
      <MenuItem to="/more" active={`${pathname === "/more"}`}><Square4/>More</MenuItem>
    </MenuList>
  )
}

const Logo = () =>{

  return(
    <Link className="nav__logo" to="/"><TnsLogo/></Link>
  )
}


class Header extends Component{

  constructor(props){
    super(props)
    this.state = {
      scrollTop:$(window).scrollTop(),
    }
  }
  componentDidMount = () =>{
    // document.title = "Bsc || Kraftly";

    window.addEventListener('scroll', this.onscroll);
  }

  componentWillUnmount(){
    // document.title = "Kraftly";
    window.removeEventListener('scroll', this.onscroll);
  }

  onscroll = () =>{
    let offset = $(window).scrollTop();
    this.setState({scrollTop:offset})
  }


  render(){
    let{isPopUp,address,scrollTop} = this.state;
    return(
      <div className={`navigation ${scrollTop < 100?"nav__lg":""}`}>
        <div className="navigation__body">
          <Logo />
          <Menu />
          <Profile />

        </div>
      </div>
    )
  }
}

export default Header;
