import React, { Component,Fragment } from 'react'
import $ from 'jquery';
import { Link ,useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {Utils,PopUp} from '../../helper'
import {ReactComponent as ActivityIcon} from '../../images/activity.svg';

const Anchor  = styled.a.attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;

class Profile extends Component{
  constructor(props){
    super(props)
    this.state = {
      address:'',
      name:"",
      isPopUp:false,
    }
  }

  componentDidMount (){
    let self = this;
    let checker = setInterval(()=>{
      let address = window.tronWeb?.defaultAddress?.base58??"";
      if(address) {
        // window.tronLink.request({method: 'tron_requestAccounts'})
        clearInterval(checker)
        self.setState({address,isPopUp:false},self.resolveAddress);
        Utils.setUser(address);
        self.autoCheckingWallet();
      }
    },500)
  }

  autoCheckingWallet = () =>{
    let self = this;
    setInterval(async()=>{
      let {address}  = self.state
      let currentAddress = window.tronWeb?.defaultAddress?.base58??"";

      if(!currentAddress && !!address){
        self.setState({address:"",name:""});
        Utils.setUser();
        Utils.setToastAlert("Wallet disconnected","warning");
      }else if (!!currentAddress && currentAddress !== address) {
        self.setState({address:currentAddress,name:""},self.resolveAddress);
        Utils.setUser(currentAddress);
        let msg = !address?"Wallet connected":"Wallet changed";
        Utils.setToastAlert(msg,"info");
      }
    },2000)
  }

  resolveAddress = async() =>{
    try {
      let {address}  = this.state;
      if(!address) return;
      let registry = await window.tronWeb.contract().at(Utils.registry);
      let name = await registry.methods["getName(address)"](address).call();
      this.setState({name});
      Utils.setUser(address,name);
    } catch (e) {
      this.setState({name:""});
    }
  }

  handleConnectPopUp = () =>{
    let address = window.tronWeb?.defaultAddress?.base58??"";
    if(!window.tronWeb){
      this.setState({isPopUp:true});
    }else if (!address) {
      Utils.setToastAlert("Unlock you Tron wallet","info");
    }
  }

  render(){
    let {address,name,isPopUp} = this.state;

    return(
      <Fragment>

        {!address && <button
          className="login__access"
          onClick={this.handleConnectPopUp}
          >
          <span>Connect Wallet</span>
        </button>}

      {!!address && <div className="header__profile">

        <div className="login__profile">
          <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path></svg>
          <div className="login__switch">
            <p>{!!name?Utils.shortName(name):Utils.shortAddress(address)}</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path></svg>
          </div>

        </div>

        <ul className="header__profile__menu" >
          <li>
            <Link to={`/search/${address}`} className="profile__option">
              <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path></svg>
              <span>Wallet</span>
            </Link>
          </li>
        </ul>

      </div>}
      {!!isPopUp && <PopUp.Modal
        title="Connect Wallet"
        onClose={()=>this.setState({isPopUp:false})}
        >
        <div className="row no-gutters">
          <div className="col-12 col-sm-9 col-lg-8 col-xl-8">
            <div className="nologin__box">
              <h3>PLEASE LOGIN TO YOUR TRON WALLET</h3>
              <p>If you do not have Tronlink wallet click "Install extension". Create Tronlink wallet and you will be able to login.</p>
              <Anchor href="https://chrome.google.com/webstore/detail/tronlink%EF%BC%88%E6%B3%A2%E5%AE%9D%E9%92%B1%E5%8C%85%EF%BC%89/ibnejdfjmmkpcnlpebklmnkoeoihofec" target="_blank">INSTALL EXTENSION</Anchor>
              <div className="nologin__box__app">
                <Anchor href="https://apps.apple.com/us/app/tronlink-trx-btt-wallet/id1453530188">
                  <img data-v-a8761414="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIcAAAAoCAMAAADjcxkDAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABLUExURY2Njf7+/gAAAA8PD6mpqaKiopKSknd3d5eXl////9zc3KqqqlFRUTExMSIiInBwcJOTk15eXn5+fj8/P2lpae3t7cjIyLy8vPb29nH7knwAAAAKdFJOU+L///9RoMTv4AF4mOzLAAADyUlEQVRYw+2Y6XLjIAyADXK6a3EfBr//k64EiesjTdtpZ5Ify9BaJjL5EJKQM0zT+Pbnue1tnKZhumiQz20QLtMwavn8FsbhAi/AAZdhkK/A8fc1OOQjDq3MYcSajzYxHz/Q9r5iMt/kgCrE4Zkwq6Luk8zH4XrP/WlMuW9yFCHwMHlQUnr6syHRnABJgqFrsI0DNCcAzXdS69I42hgk01ODo0HldGKzBPgaRxBCHNGZwyAkzCXKktICLkpUnnhnAIy+SnCZ7oryc+Mo3lcws6+K7zw6qTBiklGF/SI/5KBdUfIOh0VQkWhkjF7paiS2PoMuZBUrg5+NIes0eySUgIaUqfNtbfuiHDE7TF/iQDxvJHNEJWvjMKomRdOvHJU5srJoEt44Cn9OynbL4Ykja/2JPUBHT5sH0kal3M7rQ8keacmoCUWi575y0Ny0L7GG2QD62PYFiotFrhwGQ+cgL9P5sX/omRxDzCoWvoolbrBtzm3XTWyLtdzJT7kzuaPghRwS/9cJrk+wA3NvSwz8iLEkOf2QI4tj88/IY3Y5YqB5Boc6YizmGXkd5t/ZFYCfcZiTd6TjOYLk849bqotYiv4JRzpx2HO2r59gLAJVWZjDhl/jOLiHFQXnx0YvIvLOcAoQ5df2ZZ9tpBNOibZKoBLA9CyxEaktNxtCpGBrhQKddub2TEo9kZiHfnoK2wKHxZrcz50oMgcXpdetyAdCs8cae5SzmsD1AikWfjreBj6M23JOY7BLL8gpxjaOGXNGrg02Ys+EtZ0d2gt0Doin6FREBbImCpXpUo1V+1A8cMQTh1B2m209n8SuaS62BXqgeVexKVHwo179Iwl2KItCkyLHH8w8APMCD/LpmUPobTGgrfVtetfjhpe1Ea+OgWIJNw7f97ErdrASqOEuBo7nyymhbouy1X0sc1zdRG3Fm6LnxzqH6v7yrhjuxeKR4xwxYVujYaQ2cxRdv9y3Za7iuyMtsNrD7xW1qLa1h+e+P2DUnbFcv1Tm4JICsPnHKr4vZ2YO7PCwKqru7PCFOnkfMrPdRbXt2W5h3ycPJq/Hncg+5JKhzedSVizaUH1EMQdXxaur1ERFtvmkHqvNDEohY2yV+/paiuDgQz4U5yS34lo5VOgzkVkMeW3PLleO/g0P47a/fVMB2ZKejzsDplsSTPTeQHPa2BU3IoM472NXBOddn9C31wQTrsdmij6Gz+v1r5za7j063Lmy/9X3yv8c32hWmzviD353eInfYexlGPMLcORxmMb8ZIsA5HEapmm8vA3PbG+XcZr+AfIsVSrWvZxYAAAAAElFTkSuQmCC" alt="appstore" />
                </Anchor>
                <Anchor href="https://play.google.com/store/apps/details?id=com.tronlinkpro.wallet">
                  <img data-v-a8761414="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIkAAAAoCAMAAAD9uimwAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACHUExURUdwTF1dXf39/WdnZ6KiopeXlwAAAKSkpHNzc6KiogLi/gwMCzc3N56enrCwsADveBkZFyojJVRUVL8QX8LCwv/MAY6OjvT09ElJSerq6s/Pz9zc3Aiyx4CAgKAqbR3SZzas1ee6FIgPRAHl2qaIDg9LIwHkzck0Qw6MSIhzD2laDQ1gQg6kt+MfDKoAAAAKdFJOUwD///+azv/I/lNdrjYeAAAD2ElEQVRYw82Yi1bbOBCGozEVCrpEEqpkWTUpabtLt/v+z9cZ2TEEsCkkp8kcIIotm08z/4wuq9Vq/Umw85r4tF6hrRvJz22yQZS14JdgYr26chdB4q5WV/IiSOTV6ppfhl2/i6T1lnPVtsq2aJJL5fBT0S3F8MPir7PHk/zabn8s9bUhmM5xY3RUOpsoudJeZ42Sl9rE0HOWLY/ieJL//9lstgsj0j3SSG6qxAN5QmnOO9JZgw1pPAv6JCRfbhBl8zCnYNlZrpSS6BI/kMiJxBBXq5kw7WlIbr4hyvbXTN9guYidMkyp5z6pJD6yxgV9GpKKspmRi+gld0YaJeVI0u5JWqS0QbGGi3wikgFlhqU3Wiuuu86gaKpPIrqjRpOFEDz3DNXSnIhkRNk8vCZdKYe/cu7WierJQLJH2T7Is1W2kWSPslmuLn+DpCYzkdx/t2cmGVC2958/3/88DJGKXemqWBeLce8PptkeTbSU5717JwkFiEDQ7v970jsCpJwA9DKJAvP0q4dqWBg1+PeS3HwbQdAeUTQUj9OeL+KdJAZrc4TwIZKvdxPJz8c3dkOs3kqqZyRs8KEB9QGSr3e3/44o36f/G+BQILgm2CtBjZ1c6yaS6bYfSCKwSoIrCvvHJAhyO6A8yR6bygGHptiTDllG+fR12YCNALqSRNRTcI8kNoMjEtQa9S6J3twlt0hSQQjlIItHn4tMZtBDoYk5KXR+Fk0HkcsCujEwkEToREw1nBjVGCPeJJ2oUqi3ENAfhvE1khHkdneYcg4lN5IkCAy6KgGDo6MFQgbVIA3JgUjQgbiQKsCm3Ek4QZFPpFOtgiKhUMD8EskE8kyYsmRbJxgrEUADzXQylxEwgtBVR20lwR8RIEdbSQLurlRNPo8jQSoAbLcVd55kBNm9TJBIDuVDGmAUWCXJaiIx4CaSlhQzbun8VH+QpIWOedQKRUaAWFDsALJ7rRbakuqTUkCxooYYh8sTAdgCbVNJ9RAdqDFzL0h6chzGi1JxUO0MSQXZzdRkV1B7uGyDrHAtCYb1mdwNqMEARiINrrBHxWr0iw9ZvfRJoMeg4uj5LK4gbrZyWZ1Ie3W95jpqimEOAPQCXsIszqhYlwyumOiqrrmTJpLU8j5B0l3Cbzm5WRIC2S1WUKuYH5+XrWCDd13TDKUN88JSBlm6rvDq+NA+CCh2eoXDBqWwnt1lIMjuiF2yDegCGRYq+oGZw5L9lMTd7Y7brRvoTNlPTm9uyVOY340eu1ysMjJ/OJoemiP2xW+zuA+P5vqCTi0u5yTnck63LufEr56CNue1egr6G9ENqpaIvfAbAAAAAElFTkSuQmCC" alt="android" />
                </Anchor>
              </div>
              <span>Kraftly is available to use with Tronlink or any other Tron wallet web extension or dapp supported wallet.</span>

            </div>
          </div>
          <div className="d-none d-sm-block col-sm-3 col-lg-4 col-xl-4 align-self-center">
            <div className="nologin__box">
              <img className="tronlink__logo" src="/asset/img/tronlink.png" alt="tronlink"/>
            </div>
          </div>
        </div>
      </PopUp.Modal>}
      </Fragment>
    )
  }
}


export {
  Profile
};
