import React, { Component,useState } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {ReactComponent as SearchIcon} from '../../images/search.svg';
import {ReactComponent as RegisterIcon} from '../../images/register.svg';
import {ReactComponent as ManageIcon} from '../../images/manage.svg';

import SearchImg from '../../images/search.png';
import RegisterImg from '../../images/register.png';
import ManageImg from '../../images/manage.png';
import Trxlogo from '../../images/trx.png';

const SetUpDomain = () =>{

  const [active , setActive] = useState("search");

  return(
    <div className= "section__model bg__right">
      <div className="set__up__domain">
        <div className="set__up__domain__header">
          <div
            className={`set__up__domain__header__icons${active === "search"?" active":""}`}
            onClick={()=>setActive("search")}
            >
            <SearchIcon/>
            <p>SEARCH</p>
            <span>&nbsp;</span>
          </div>
          <div
            className={`set__up__domain__header__icons${active === "register"?" active":""}`}
            onClick={()=>setActive("register")}
            >
            <RegisterIcon/>
            <p>REGISTER</p>
            <span>&nbsp;</span>
          </div>
          <div
            className={`set__up__domain__header__icons${active === "manage"?" active":""}`}
            onClick={()=>setActive("manage")}
            >
            <ManageIcon/>
            <p>MANAGE</p>
            <span>&nbsp;</span>
          </div>
        </div>

        <div className={`set__up__domain__imgs ${active}`}>
          <div
            className={`set__up__domain__img${active === "search"?" active":""}`}
            onClick={()=>setActive("search")}
            >
            <img src={SearchImg} alt=""/>
          </div>
          <div
            className={`set__up__domain__img${active === "register"?" active":""}`}
            onClick={()=>setActive("register")}
            >
            <img src={RegisterImg} alt=""/>
          </div>
          <div
            className={`set__up__domain__img${active === "manage"?" active":""}`}
            onClick={()=>setActive("manage")}
            >
            <img src={ManageImg} alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}


const UserInfo = (props) =>{

  return(
    <div className= "section__model bg__left">
      <h1 className="section__title">Your web3 username</h1>
      <p className="section__desc">No more sandboxed usernames. Own your username, store an avatar and other profile data, and use it across services.</p>

      <div className="home__user__example">
        <img src={Trxlogo} alt="" />
        <p>yourname.trx</p>
      </div>
    </div>
  )
}

const AddressInfo = (props) =>{

  return(
    <div className= "section__model bg__right">
      <h1 className="section__title">One Name For All Your Addresses</h1>
      <p className="section__desc">No more copying and pasting long addresses. Use your ENS name to store all of your addresses and receive any cryptocurrency, token, or NFT.</p>
        <div className="home__addresses__example">
          <img src={Trxlogo} alt="" />
          <img src={require('../../images/btc.png')} alt="" />
          <img src={require('../../images/eth.png')} alt="" />
          <img src={require('../../images/bsc.png')} alt="" />
          <img src={require('../../images/ltc.png')} alt="" />
          <img src={require('../../images/doge.png')} alt="" />
          <img src={require('../../images/ftm.png')} alt="" />
          <img src={require('../../images/matic.png')} alt="" />
          <img src={require('../../images/etc.png')} alt="" />
          <img src={require('../../images/xrp.png')} alt="" />
        </div>
    </div>
  )
}



const HowDoesItWorks = (props) =>{

  return(
    <div className= "section__model bg__left">
      <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="how__it__works">
            <p>How does it works?</p>
            <OwlCarousel
              mouseDrag = {false}
              touchDrag = {false}
              dots = {true}
              loop = {true}
              autoplay = {true}
              autoplayHoverPause = {true}
              autoplayTimeout = {6000}
              smartSpeed = {3000}
              items = {1}
              margin={0}
              >
              <div className="how__it__works__body">
                Also included in the contract is a custom marketplace that supports standard buy, bid and ask transactions.
              </div>
              <div className="how__it__works__body">
                Also included in the contract is a custom marketplace that supports standard buy, bid and ask transactions.
              </div>
            </OwlCarousel>

          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card__banner azure__shadow">
            <div className="box_shadow shadow__below__sm"></div>
            <h1>$ 500,000</h1>
            <h2>Mega Lottery</h2>
            <p>Each Skeleton is considered as one lottery ticket of Mega Lottery</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export {
  SetUpDomain,
  HowDoesItWorks,
  UserInfo,
  AddressInfo,
}
