
import React,{ useEffect ,Fragment} from 'react'
import { Routes, Route ,useLocation,BrowserRouter} from 'react-router-dom';

import styled from 'styled-components'
import * as pages from '../pages'
import {Header,Footer} from '../components'

const Layout = (props) =>{
  return(
    <Fragment>
        {props.children}
      <Footer/>
    </Fragment>
  )
}



function App() {

    return (
      <BrowserRouter>
        <Header/>

        <Routes>

          <Route index  element={<Layout><pages.Home/></Layout>} />
          <Route path="/home" element={<Layout><pages.Home/></Layout>} />


          <Route path="/search" element={<pages.Search.SearchPage />} />
          <Route path="/search/:domain" element={<pages.Search.SearchResult />} />

          <Route path="/name/:domain/register" element={<pages.Domain.Register />} />
          <Route path="/name/:domain/details" element={<pages.Domain.Details />} />
          <Route path="/name/:domain/subdomains" element={<pages.Domain.Subdomains />} />

          <Route path="/explore" element={<pages.Home />} />

        </Routes>

      </BrowserRouter>
    )
}

export default App
